import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./clients-list.yaml"

const ClientsList = () => {
  return (
    <section className="clients_area pad_btm">
      <Container>
        <Row>
          <Col>
            <div className="clients_content">
              <h2>{YAMLdata.title}</h2>
              <p>{YAMLdata.content}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="client_logo_list">
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_01.png"
                alt="Shiro"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_02.png"
                alt="Prost Brew Pub"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_03.png"
                alt="Badmaash"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_04.png"
                alt="Byg Brewski"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_05.png"
                alt="Café Noir"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_06.png"
                alt="Cantan"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_07.png"
                alt="Asilo"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_08.png"
                alt="XOOX Brewmill"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_09.png"
                alt="Hard Rock Cafe"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_10.png"
                alt="Playboy"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_11.png"
                alt="California Pizza Kitchen"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_12.png"
                alt="Olive Bar &amp; Kitchen"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_13.png"
                alt="Punjab Grill"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_14.png"
                alt="Serafina New York"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_15.png"
                alt="The Pump House"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_16.png"
                alt="Barbecue By Punjab Grill"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_17.png"
                alt="Monkey Bar"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_18.png"
                alt="The Fatty Bao"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_19.png"
                alt="Tim Tai"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_20.png"
                alt="Manchester United Cafe Bar"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_21.png"
                alt="The Brewhive"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_22.png"
                alt="All Good Deli"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_23.png"
                alt="Gateway Taproom"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_24.png"
                alt="Hangover"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_25.png"
                alt="Guppy"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_26.png"
                alt="Bob's Bar"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_27.png"
                alt="Nevermind Bar &amp; Social"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_28.png"
                alt="Blue Frog Bangalore"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_29.png"
                alt="Mirage"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_30.png"
                alt="PlanB House of Wings"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_31.png"
                alt="Ministry of Curries"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_32.png"
                alt="Tycoon's"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_33.png"
                alt="VRO Hospitality"
              />
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}
export default ClientsList
